import { render, staticRenderFns } from "./FeedView.vue?vue&type=template&id=7c9bf664&"
import script from "./FeedView.vue?vue&type=script&lang=js&"
export * from "./FeedView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QSpinnerIos from 'quasar/src/components/spinner/QSpinnerIos.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSpinnerIos,QChip,QTooltip,QItemLabel});
