<template>
  <div
    :class="
      isLoading
        ? 'bg-white border-radius-5 border-box q-pr-sm q-pl-xs q-py-md'
        : 'q-pr-sm q-pl-xs'
    "
  >
    <div
      v-if="isLoading"
      class="row items-center full-height"
    >
      <div class="col-auto q-mx-auto">
        <q-spinner-ios
          size="45px"
          color="primary"
        />
      </div>
    </div>

    <div v-else>
      <div class="row bg-white border-radius-5 border-box q-px-md q-py-sm">
        <div class="col">
          <div class="column full-height">
            <div
              class="col-auto"
              style="font-size: 0.9em"
            >
              {{ doc.datetime }} - {{ docType }}
            </div>
            <div
              v-if="doc.documentTypeId === materiaDeInternet"
              class="col row items-center"
            >
              <span class="col title">
                <a
                  target="_blank"
                  :href="doc.url"
                >Link da Matéria</a></span>
            </div>
            <div class="col row items-center">
              <span class="col title bold">{{
                doc.scheduleTitle || doc.description
              }}</span>
            </div>
          </div>
        </div>

        <div class="col-auto">
          <div class="row items-center">
            <div class="col-auto text-right column">
              <small class="col-auto">{{ doc.agencyLabel }}</small>
              <small class="col-auto">{{ doc.degreeName }}</small>
              <div
                v-if="boardCouncil.length"
                class="col-auto row justify-end items-center"
              >
                <template v-for="item in boardCouncil">
                  <q-chip
                    square
                    outline
                    size="0.8em"
                    color="primary"
                    class="col-auto q-px-xs q-mx-xs q-py-none"
                    :key="item.id"
                  >
                    {{ item.prefix }}
                    <q-tooltip>{{ item.name }}</q-tooltip>
                  </q-chip>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="doc.scheduleResume"
        class="bg-white border-radius-5 border-box q-px-sm q-py-xs q-mt-xs"
        style="white-space: pre-wrap"
      >
        <q-item-label
          caption
          class="q-mb-sm q-mt-xs"
        >
          Divulgação
        </q-item-label>

        <span v-html="doc.scheduleResume" />
      </div>

      <div
        v-if="images.length"
        class="bg-white border-radius-5 border-box q-px-sm q-py-xs q-mt-xs"
      >
        <q-item-label
          caption
          class="q-mt-xs"
        >
          Imagens
        </q-item-label>

        <FieldImages
          readonly
          size="banner_665_150"
          :images="images"
        />
      </div>

      <div
        v-if="attachments.length"
        class="bg-white border-radius-5 border-box q-px-sm q-py-xs q-mt-xs"
      >
        <q-item-label
          caption
          class="q-my-xs"
        >
          Anexos
        </q-item-label>

        <FieldAttachments
          readonly
          size="banner_665_150"
          :value="attachments"
        />
      </div>

      <span class="q-mb-xl">&nbsp;</span>
    </div>
  </div>
</template>

<script>
import api from '@/aws/api'
import moment from '@/components/moment'
import log from '@/utils/log'
import FieldImages from '@/components/forms/FieldImages'
import FieldAttachments from '@/components/forms/FieldAttachments'
import { MATERIA_DE_INTERNET } from '../../documents/constants'

const ignoreSubTypes = [392]

export default {
  components: {
    FieldImages,
    FieldAttachments
  },

  data () {
    return {
      doc: {},
      materiaDeInternet: MATERIA_DE_INTERNET,
      isLoading: true
    }
  },

  mounted () {
    this.loadFeed()
  },

  computed: {
    feedId () {
      return this.$route.params.id
    },

    attachments () {
      const doc = this.doc
      return Array.isArray(doc?.attachments) ? doc.attachments : []
    },

    boardCouncil () {
      const doc = this.doc
      return Array.isArray(doc?.boardCouncil) ? doc.boardCouncil : []
    },

    images () {
      const doc = this.doc
      return Array.isArray(doc?.images) ? doc.images : []
    },

    docType () {
      const vm = this
      const {
        documentTypeId,
        documentTypeName,
        documentSubTypeName,
        numeration,
        materialDate
      } = vm.doc
      let subTitle = documentTypeName
      const concat = (label) =>
        (subTitle = (subTitle ? `${subTitle} ` : '').concat(label))
      const canSetSubType =
        !ignoreSubTypes.includes(documentTypeId) && documentSubTypeName
      if (canSetSubType) concat(documentSubTypeName)
      if (numeration) {
        concat(
          `Nº ${String(numeration).padStart(4, '0')}/${moment
            .unix(materialDate)
            .format('YYYY')}`
        )
      }
      return subTitle
    }
  },

  methods: {
    loadFeed () {
      this.isLoading = true

      api.getFeed(this.feedId).then(async (feed) => {
        if (!feed) return this.$router.go(-1)
        const datetime = moment.unix(feed.scheduleAt).format('DD/MM HH:mm')
        this.doc = { ...feed, datetime }
        this.isLoading = false
      }).catch((err) => {
        log(err)
      })
    }
  },

  watch: {
    feedId: {
      deep: true,
      handler () {
        this.loadFeed()
      }
    }
  }
}
</script>
